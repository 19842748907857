@import "variables.less";

/** TODO: Update these when accounts is in UI and there is less to override.
  * then we can update the styles to only worry about the login/register/checkout pages.
  */
body {
  font-family: "Open Sans", Arial, sans-serif;
}
a {
  color: @brand-primary;
}
a:hover,
a:focus {
  text-decoration: none;
  color: darken(@brand-primary, 6%);
}
.navbar {
  background-color: #1a192b;
  .container {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }
  .navbar-header {
    display: flex;
    align-items: center;
  }
  .nav-link {
    padding-left: 8px;
    a {
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 0 6px;
      border-radius: 4px;
      line-height: 32px;
      display: inline-block;
      &:hover {
        background: rgba(255, 255, 255, 0.12);
      }
    }
  }
}
body,
.header-wrapper {
  background: #f0f2f5;
}
.header-container {
  padding-top: 16px;
}
.main-container {
  padding-top: 32px;
}
h2.content-header {
  margin-top: 0;
  line-height: 48px;
  margin-bottom: 24px;
  color: #0e0d34;
  font-weight: 700;
  font-size: 20px;
}
.action-links li.add_api_token_exchange a,
.action-links li.add a,
.add-service a,
.add-service .link-disabled {
  text-transform: none !important;
  line-height: 38px;
  height: 38px;
  padding: 0 16px;
  font-weight: 600;
  color: #fff;
  background-color: @brand-primary;
  border-radius: 4px;
  display: inline-block;
}
.add-service .link-disabled {
  opacity: 0.8;
  cursor: not-allowed;
}
.account-settings-tab {
  h2.content-header {
    padding-left: 32px;
  }
  .region-content {
    #block-system-main {
      background: transparent;
      padding: 0 0 0 32px;
      ul.action-links {
        position: absolute;
        top: 8px;
        right: 15px;
        margin: 0;
      }
    }
  }
  .pane-blimp-vouchers-account-credit-summary-pane {
    background: transparent;
    padding: 0;
  }
  .table-striped > tbody > tr:nth-of-type(odd) {
    background: transparent;
  }
}
.logged-in.page-user {
  .header-container header {
    display: flex;
    align-items: flex-start;
    .back {
      padding-right: 24px;
      border-right: 1px solid rgba(50, 50, 76, 0.2);
      a {
        color: #4b6180;
        display: inline-flex;
        align-items: center;
        height: 32px;
        line-height: 32px;
        font-size: 13px;
        font-weight: 600;
        span {
          height: 32px;
          line-height: 32px;
          &.icon {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}
.profile h4 {
  display: none;
}
#sliding-popup.sliding-popup-bottom,
#sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner,
.eu-cookie-withdraw-tab {
  background: #f5f7fa;
  .popup-content {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    #popup-text {
      margin: 0;
      color: #38485e;
      line-height: 19px;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 60px;
      font-weight: normal;
      max-width: 100%;
      .eu-cookie-compliance-more-button {
        color: @brand-primary !important;
        text-decoration: none;
        font-size: 15px;
        line-height: 19px;
        margin-left: 4px;
      }
    }
    #popup-buttons {
      margin: 0;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      max-width: 100%;
      button {
        background: @brand-primary;
        height: 32px;
        line-height: 32px;
        padding: 0 16px;
        min-width: 92px;
        text-align: center;
        border-radius: 4px;
        font-size: 15px;
        text-transform: none;
        &:hover {
          background: @brand-primary;
        }
      }
      .agree-button {
        color: #fff !important;
      }
      .decline-button {
        background: transparent;
        color: @brand-primary;
        &:hover {
          background: transparent;
          text-decoration: underline;
        }
      }
    }
  }
}
#user-widget {
  .name-or-pic {
    display: flex;
    align-items: center;
  }
  .user-widget__accounts_menu_toggle {
    padding: 0 35px 0 3px;
    height: 32px;
    &:hover,
    &:focus,
    &:active {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
    }
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      height: 16px;
      width: 16px;
      top: 12px;
      right: 8px;
      background: transparent url("../images/icon-chevron-white.svg") no-repeat
        center center;
    }
  }
  .user-widget__accounts_menu_toggle--active {
    &:after {
      transform: rotate(180deg);
    }
  }
  .user-widget__avatar {
    max-height: 24px;
    max-width: 24px;
    border-radius: 2px;
  }
  .user-widget__avatar + .user-widget__user_name {
    display: none;
  }
  .user-widget__dropdown__menu--account {
    padding: 8px;
    width: 214px;
    min-width: 214px;
    box-sizing: border-box;
    left: auto;
    right: 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(152, 160, 171, 0.4);
    border-radius: 4px;
    a {
      color: #38485e;
      font-size: 15px;
      padding: 0 16px;
      line-height: 32px;
      &:hover {
        background: rgba(134, 181, 255, 0.2);
        border-radius: 4px;
      }
    }
  }
  .beta-switch {
    margin: 8px -8px -8px;
    label {
      min-width: 0;
    }
  }
}
@media (min-width: 768px) {
  .nav-tabs {
    padding-left: 0;
    > li {
      padding: 0 12px;
      &:first-child {
        padding-left: 0;
      }
      > a {
        color: #4b6180;
        font-size: 13px;
        font-weight: 600;
        padding: 5px 0 5px;
        line-height: 22px;
        letter-spacing: 1.6px;
        &:hover {
          color: #0e0d34;
        }
      }
      &.active > a,
      &.active > a:hover,
      &.active > a:focus,
      &:hover a {
        color: #0e0d34;
        border-color: #32324c;
        padding: 5px 0 5px;
      }
    }
  }
  body.ui-alpha .nav-tabs {
    > li:first-child {
      padding-left: 24px;
    }
  }
  .account-settings-tab .tabs--secondary {
    margin-top: 32px;
    li {
      margin-bottom: 8px;
      a {
        color: #38485e;
        font-size: 16px;
        line-height: 48px;
        padding: 0 24px;
        width: 200px;
        text-transform: none;
        font-weight: 600;
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active a,
      a:hover {
        background: #fff;
        color: #38485e;
        box-shadow: 0 10px 20px rgba(75, 97, 128, 0.08);
      }
      &.active a {
        color: @brand-primary;
      }
    }
  }
}
.page-user-orders .current-order-full #order-toggle {
  background: transparent url("../images/icon-more.png") no-repeat 5px center;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  &.close {
    background: #fff url("../images/icon-less.png") no-repeat 5px center;
  }
  &:before {
    display: none;
  }
}
.view-platforms-user-view {
  padding-top: 0;
  .filter-wrapper {
    margin-bottom: 32px;
    .form-type-select,
    .views-exposed-widget {
      width: 164px;
      &.views-submit-button {
        width: 0;
      }
      &.views-widget-filter-owner {
        display: none;
      }
    }
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .view-filters {
        padding-right: 0;
      }
    }
    @media (min-width: 992px) {
      position: absolute;
      top: 0;
      right: 15px;
      margin: 0;
      width: auto;
    }
  }
  .views-exposed-widgets,
  .add-service {
    margin: 0;
  }
  .info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .usage-item {
      width: auto;
      text-align: left;
    }
  }
  .usage-item span {
    width: 100%;
    padding-right: 30px;
    &.fa {
      display: none;
    }
  }
  .usage-environments {
    span {
      background: transparent;
    }
  }
  .usage-storage {
    span {
      background: transparent;
    }
  }
  .usage-users {
    span {
      background: transparent;
    }
  }
  .edit-link {
    .edit-dropdown {
      background: transparent url("../images/icon-more.svg") no-repeat right top;
      &:before,
      &:after {
        content: none;
      }
      a:hover {
        background: @brand-primary;
      }
    }
    a {
      font-family: "Open Sans", Arial, sans-serif;
    }
    .owner {
      color: #4b6180;
      top: 16px;
      right: 50px;
      .fa {
        display: none;
      }
    }
  }
  .view-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 0;
  }
  .views-row {
    width: 100%;
    .platform-title {
      color: #252428;
      font-size: 18px;
      font-weight: 700;
      margin-right: 90px;
    }
    .plan {
      color: #38485e;
      font-size: 15px;
      font-weight: 600;
    }
    &:hover a {
      .platform-title,
      .plan {
        color: @brand-primary;
      }
      .plan {
        border-bottom-color: @brand-primary;
      }
    }
  }
  @media (min-width: 768px) {
    .views-row {
      width: 344px;
      margin-right: 32px;
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 992px) {
    .views-row {
      width: 362px;
      &:nth-child(3n) {
        margin-right: 32px;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 1024px) {
    .views-row {
      width: 432px;
    }
  }
  @media (min-width: 1280px) {
    .view-content {
      justify-content: flex-start;
    }
    .views-row {
      width: 370px;
    }
    .views-row:nth-child(2n) {
      margin-right: 32px;
    }
    .views-row:nth-child(3n) {
      margin-right: 0;
    }
  }
  @media (min-width: 1440px) {
    .views-row {
      width: 422px;
    }
  }
}
#block-commerce-checkout-progress-indication {
  li {
    &.visited:before {
      content: "";
      background: #fff url("@{image-path}/icon-check-progress.png") no-repeat
        5px 5px;
    }
  }
}
#payment-container .payment-header .title {
  background: transparent url("@{image-path}/secure-sm.png") no-repeat left
    center;
  .fa {
    display: none;
  }
}
body.not-logged-in.page-user,
body.not-logged-in.page-platform-login,
body.not-logged-in.page-user-password,
body.free-trial-checkout.login_register {
  background: #fff;
  color: #38485e;
  height: auto;
  min-height: ~"calc(100vh + 100px)";
  .navigation-wrapper,
  .header-wrapper,
  footer.footer {
    background: transparent !important;
    font-size: 14px;
    .container {
      width: auto;
      padding: 0 40px;
    }
  }
  .header-wrapper {
    padding-top: 84px;
  }
  .region-content {
    position: relative;
    > h2 {
      display: none;
    }
  }
  a {
    color: #4786ff;
    font-weight: 600;
  }
  a.logo {
    height: 24px;
    width: 122px;
    padding: 0;
    margin: 0 0 0 25px;
    @media (min-width: 768px) {
      top: 56px;
      right: 56px;
      position: absolute;
      margin: 0;
    }
  }
  .btn-success,
  .btn-primary,
  .btn-info,
  .btn-default,
  .form-submit,
  button {
    height: 40px;
    line-height: 40px;
    padding: 0 16px;
    text-transform: none;
    font-size: 15px;
    font-weight: 600;
  }
  .header-wrapper {
    padding-top: 18px;
    h1 {
      text-align: left;
    }
    .messages {
      margin: 0 40px 32px;
      width: auto;
      .container {
        max-width: 100%;
      }
      .message-content {
        padding: 32px 0 32px 56px;
      }
    }
  }
  .block-openid-connect {
    form > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  footer.footer {
    height: 58px;
    margin: 0;
    text-align: right;
    color: #4b6180;
    a {
      color: #4786ff;
    }
    .container {
      padding: 0 40px;
    }
  }
  .divider {
    text-align: left;
    width: 100%;
    margin: 0;
    .text {
      padding: 0;
    }
  }
  #user-login,
  #login-form,
  #user-register-form,
  #user_login_form {
    text-align: left;
    .login-password {
      color: #4786ff;
      font-size: 13px;
      line-height: 24px;
      font-style: normal;
      font-weight: 600;
    }
    .privacy {
      font-size: 14px;
    }
    .form-submit {
      margin: 0 0 32px;
    }
  }
  #login-form {
    width: 336px;
    min-height: ~"calc(100vh - 415px)";
    padding-bottom: 40px;
  }
  .form-submit.btn-social {
    margin: 0 4px 0 0;
    border-radius: 4px;
    padding: 0 16px 0 38px;
  }
  #openid-connect-login-form input {
    display: none;
  }
  .divider {
    border: none;
    height: auto;
    margin-bottom: 24px;
    .text {
      color: inherit;
      text-transform: none;
      background: transparent;
    }
  }
  h1.page-header {
    color: #1a192b;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 24px;
  }
  .header-container,
  .main-container,
  .footer-wrapper {
    width: 100%;
  }
  .main-container {
    padding: 0 40px;
    h2 {
      font-size: 15px;
      font-weight: normal;
      line-height: 19px;
      margin: 0 0 56px;
    }
  }
  #user-register-form #register-toggle .form-fields,
  #login-form #register-toggle .form-fields,
  #user-register-form #user-login .form-fields,
  #login-form #user-login .form-fields,
  #user-pass .form-fields {
    width: 100%;
  }
  #user-pass {
    margin: 0;
  }
  .user-info-from-cookie {
    max-width: 100%;
    text-align: left;
  }
  .form-actions .btn {
    background: #4786ff;
    border-radius: 4px;
    width: 336px;
    max-width: 100%;
  }
  #block-openid-connect-openid-connect-login {
    margin-bottom: 40px;
  }
  #commerce-checkout-form-login-register .require-message,
  #user-register-form .require-message,
  #login-form .require-message,
  #user-login .require-message {
    position: relative;
    margin-left: 0;
    font-size: 13px;
    line-height: 18px;
    text-transform: none;
    left: 0;
    top: 0;
  }
  .email-support {
    left: auto;
    right: 0;
  }
  .login-footer {
    width: 100%;
    .switch-form,
    .back-to-login,
    .email-support {
      width: auto;
      padding: 0;
      margin: 0;
      border-top: none;
      font-size: 14px;
      color: #4b6180;
      text-align: left;
    }
    .container {
      padding: 0;
    }
  }
  @media (min-width: 768px) {
    #login-form {
      margin: 0 auto;
    }
    #navbar {
      min-height: auto;
    }
    .navbar-fixed-top {
      position: static;
    }
    .navigation-wrapper {
      padding: 0;
      height: 116px;
    }
    .page-wrapper {
      padding-top: 0;
    }
    .header-container {
      padding-top: 0;
    }
    .user-info-from-cookie {
      width: 100%;
    }
  }
}
body.not-logged-in.page-user-register,
body.free-trial-checkout.login_register {
  .header-container,
  .main-container,
  .messages {
    margin-left: 0 !important;
  }
  .header-container,
  .main-container,
  .footer-wrapper,
  .messages {
    max-width: 724px;
  }
  .main-container {
    max-width: 914px;
  }
  a.logo {
    left: 56px;
    right: auto;
  }
  .login-footer {
    padding: 0;
  }
}
body.page-user-login {
  .region-content > h2 {
    display: none;
  }
}
body.not-logged-in.page-user-register,
body.free-trial-checkout.login_register {
  background: #fff;
  height: auto;
  .header-container header,
  .main-container > .row {
    margin-left: 0 !important;
  }
  .login-footer {
    margin-top: 38px;
    position: absolute !important;
    padding: 0;
    top: 100%;
    .container {
      float: none;
      margin: 0 auto;
      &:before,
      &:after {
        content: none;
      }
    }
  }
  @media (min-width: 768px) {
    .navbar-fixed-top {
      position: relative;
    }
    .main-container .row {
      margin-left: 0 !important;
    }
  }
}
body.not-logged-in.page-user-password {
  .header-wrapper h1.page-header {
    text-align: left !important;
    margin: 0 auto 24px;
    @media (min-width: 768px) {
      width: 436px;
    }
  }
}
body.page-user-projects-edit {
  h2.content-header {
    display: none;
  }
  h2.form-title {
    margin-top: 0;
    line-height: 48px;
    margin-bottom: 24px;
    color: #0e0d34;
    font-weight: 700;
    font-size: 20px;
  }
  p.page-description {
    display: none;
  }
}
#blimp-platform-pricing-form #features ul.active li.base-feature-1,
#blimp-platform-pricing-form #features ul.active li.base-feature-2,
#blimp-platform-pricing-form #features ul.active li.base-feature-3 {
  background-image: url("../images/icon-check.png");
}
.page-user-support .region-content #block-system-main {
  padding-top: 0;
  position: relative;
  .action-links {
    margin: 0;
    position: absolute;
    top: -67px;
    right: 0;
  }
}
@import "forms.less";
@import "messages.less";
@import "responsive.less";
