@import 'variables.less';

.messages {
  .message-wrapper.container {
    max-width: 100%;
  }
  .message-content .fa {
    display: none;
  }
  &.error {
    .message-content {
      background: transparent url("@{image-path}/icon-error-circle.png") no-repeat 0 35px;
    }
  }
  &.status {
    .message-content {
      background: transparent url("@{image-path}/icon-status-circle.png") no-repeat 0 35px;
    }
  }
  &.warning {
    .message-content {
      background: transparent url("@{image-path}/icon-warning-circle.png") no-repeat 0 35px;
    }
  }
  &.info {
    .message-content {
      background: transparent url("@{image-path}/icon-info-circle.png") no-repeat 0 35px;
    }
  }
}
.region-help {
  padding: 15px 20px;
}
