@media (min-width: 768px) {
  body.page-checkout.login_register,
  body.not-logged-in.page-error {
    .login-footer {
      position: absolute;
      top: 100%;
      left: 0;
      margin-top: 32px;
      .container {
        .switch-form {
          border-top: none;
          width: auto;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
  body.not-logged-in.page-user,
  body.not-logged-in.page-platform-login,
  body.not-logged-in.page-user-password,
  body.free-trial-checkout.login_register {
    background: #fff url("../images/signin-art@2x.png") no-repeat 0 0;
    background-attachment: fixed !important;
    background-size: auto 100%;
    .page-wrapper {
      margin-left: 50%;
      background: #fff;
      overflow: hidden;
      .container {
        max-width: 100% !important;
      }
    }
    .header-wrapper {
      padding-top: 84px;
      h1.page-header {
        font-size: 32px;
        text-align: center;
      }
    }
    .region-content {
      > h2 {
        display: block;
      }
    }
    .form-submit.btn-social {
      padding: 0 26px 0 54px;
      margin: 0 8px 0 0;
    }
    .form-actions .btn {
      background-color: #145CC6;
      border-radius: 2px;
    }
    a {
      color: #145CC6;
    }
    .divider {
      text-align: center;
      margin: 0 auto 24px;
    }
    .block-openid-connect {
      min-width: 360px;
      form > div {
        justify-content: center;
      }
    }
    #user-pass {
      margin: 0 auto;
    }
    .login-footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      box-sizing: border-box;
      .container {
        width: 100%;
      }
    }
    #login-form .login-footer {
      padding: 0;
    }
  }
  body.not-logged-in.page-user-password {
    background-image: url("../images/reset-password@2x.png");
    background-image: url("../images/reset-password@2x.png"), linear-gradient(90deg, #FFC9C6, #FFC9C6 100%, #fff 100%);
    .header-container > header {
      padding-top: 129px;
    }
  }
  .page-user .region-help {
    margin-left: 159px;
  }
  .account-settings-tab #block-system-main,
  .account-settings-tab h2.content-header {
    margin-left: 200px;
  }
  #commerce-checkout-form-login-register .form-fields .ok:before,
  #user-register-form .form-fields .ok:before,
  #login-form .form-fields .ok:before,
  .page-platform #user-login .form-fields .ok:before,
  #commerce-checkout-form-login-register .form-fields .error:before,
  #user-register-form .form-fields .error:before,
  #login-form .form-fields .error:before,
  .page-platform #user-login .form-fields .error:before,
  #commerce-checkout-form-login-register .form-item.ok:before,
  #user-register-form .form-item.ok:before,
  #login-form .form-item.ok:before,
  .page-platform #user-login .form-item.ok:before,
  #commerce-checkout-form-login-register .form-item.error:before,
  #user-register-form .form-item.error:before,
  #login-form .form-item.error:before,
  .page-platform #user-login .form-item.error:before {
    content: none;
  }
  body.not-logged-in.page-user,
  body.not-logged-in.page-platform-login {
    .region-content > h2 {
      display: block;
    }
  }
  body.navbar-is-fixed-top,
  body.admin-menu.navbar-is-fixed-top {
    padding-top: 63px !important;
  }
  body.not-logged-in.page-user-register,
  body.free-trial-checkout.login_register {
    .page-user-register {
      position: relative;
    }
    .page-wrapper {
      margin-right: 50%;
      margin-left: 0;
    }
  }
  #user-register-form #register-toggle .form-fields,
  #login-form #register-toggle .form-fields,
  #user-register-form #user-login .form-fields,
  #login-form #user-login .form-fields,
  #user-pass .form-fields,
  .user-info-from-cookie {
    width: 336px;

    .form-actions .btn {
      width: 336px;
    }
  }
  .user-info-from-cookie {
    max-width: 336px;
    text-align: left;
  }
  .form-actions .btn {
    background: #4786ff;
    border-radius: 4px;
  }
}
@media (min-width: 992px) {
  .page-user .region-help {
    margin-left: 189px;
  }
  .account-settings-tab #block-system-main,
  .account-settings-tab h2.content-header {
    margin-left: 200px;
  }
}
@media (min-width: 1200px) {
  body.not-logged-in.page-user,
  body.not-logged-in.page-platform-login,
  body.not-logged-in.page-user-password,
  body.free-trial-checkout.login_register,
  body.page-checkout.login_register,
  body.not-logged-in.page-error {
    .login-footer {
      .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
@media (min-width: 1440px) {
  body.not-logged-in.page-user,
  body.not-logged-in.page-platform-login,
  body.not-logged-in.page-user-password,
  body.free-trial-checkout.login_register {
    background-size: 48.75% auto;
  }
}
.container {
  @media (min-width: 1024px) {
    max-width: 944px;
    width: 944px;
  }
  @media (min-width: 1280px) {
    max-width: 1204px;
    width: 1204px;
  }
  @media (min-width: 1440px) {
    max-width: 1360px;
    width: 1360px;
  }
  @media (min-width: 1920px) {
    max-width: 1680px;
    width: 1680px;
  }
}
