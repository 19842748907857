@import "variables.less";
.btn-success,
.btn-primary,
.btn-info,
.btn-default,
.form-submit,
button,
#blimp-user-billing-address-form .btn-info {
  background-color: @button;
  color: #fff;
  border: none;
  border-radius: 2px;
  padding: 0px 16px 2px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  &:hover {
    background-color: @button-hover;
  }
  &:focus {
    border: 1px solid @button-hover;
    margin: -1px;
    line-height: 30px;
    box-shadow: 0 0 2px @button-hover;
    outline: none;
  }
  &:active {
    margin: 0;
    box-shadow: none;
    border: none;
    background-color: @button-hover;
  }
}
.form-actions .btn {
  padding: 0 16px;
  font-size: 15px;
  font-weight: 600;
}
#edit-cancel {
  font-weight: 600px;
  margin-left: 16px;
  color: #4b6180;
}
select,
select.form-control,
.chosen-container-single .chosen-single,
.chosen-container-multi.chosen-with-drop .chosen-choices {
  background: #f5f7fa !important;
  &:after {
    display: none;
  }
}
.chosen-container-single .chosen-single,
.chosen-container-multi .chosen-choices {
  background: #f5f7fa !important;
}
.chosen-container-multi.chosen-with-drop .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-single {
  background: #f5f7fa !important;
}
.chosen-container-single .chosen-single div b,
.chosen-container-multi .chosen-choices div b,
.chosen-container-active.chosen-with-drop .chosen-single div b {
  background: transparent url("../images/icon-chevron.svg") no-repeat center
    center !important;
}
.chosen-container .chosen-results {
  color: #38485e;
  li {
    min-height: 40px;
    line-height: auto;
    font-size: 15px;
    padding: 8px;
    box-sizing: border-box;
    color: #38485e;
    display: flex !important;
    align-items: center;
  }
  .highlighted {
    background: rgba(134, 181, 255, 0.2) !important;
    border-radius: 4px;
    color: #38485e !important;
  }
}
body.platform-styles {
  .password-wrapper {
    position: relative;
    button.toggle-pass {
      background: transparent !important;
      border: none;
      padding: 0;
      position: absolute;
      top: 0;
      left: 244px;
      height: 40px;
      width: 40px;
      border-radius: 0;
      &.show {
        svg path {
          fill: @brand-primary;
        }
      }
      &:focus,
      &:active {
        margin: 0;
        box-shadow: none;
      }
      @media (min-width: 992px) {
        left: auto;
        right: 0;
      }
    }
  }
}
#toggle-pass-1 {
  right: auto !important;
  left: 244px;
  @media (min-width: 992px) {
    left: 40%;
  }
}
@media (max-width: 992px) {
  #toggle-confirm {
    top: 60px;
  }
}
#views-exposed-form-platforms-user-view-page {
  .form-item-owned {
    width: 185px;
    text-transform: none;
    height: 40px;
    display: flex;
    align-items: center;
    input[type="checkbox"],
    a {
      top: 0;
      position: relative;
      margin: 0;
      text-indent: 0;
    }
    input[type="checkbox"] {
      margin-right: 16px;
    }
    label {
      height: 40px;
      display: flex !important;
      align-items: center;
      font-size: 15px;
    }
  }
  .views-exposed-widget {
    padding: 0;
    margin: 0 16px 0 0;
    &.views-submit-button,
    &.views-widget-filter-owner {
      margin: 0;
    }
  }
  select,
  .chosen-container-single .chosen-single {
    background: #fff !important;
    border: 1px solid #c9d0e4;
    border-radius: 4px;
    &:hover,
    &:focus {
      background-color: #fff;
    }
  }
  .form-text {
    border: 1px solid #c9d0e4;
    border-radius: 4px;
    background: #fff;
  }
  .usage-environments span,
  .usage-storage span,
  .usage-users span {
    background: none;
  }
}
#edit-platform-customization
  .custom-field-wrapper
  .chosen-container-single
  .chosen-single {
  background-color: #e3e3e3 !important;
  background-position: 90% center !important;
}
#blimp-platform-pricing-form #features ul.active li {
  background: @brand-light url("@{image-path}/icon-check2.png") no-repeat 20px
    7px;
  &:before {
    content: "";
  }
  &.base-feature-1,
  &.base-feature-2,
  &.base-feature-3 {
    background: transparent url("@{image-path}/icon-check.png") no-repeat 0 7px;
  }
  &.base-feature-1,
  &.base-feature-4 {
    background-position: 20px 21px;
  }
  &.base-feature-1 {
    background-position: 0 21px;
  }
}
#blimp-platform-pricing-form #features #development {
  ul.active li.base-feature-4 {
    background: transparent url("@{image-path}/icon-check.png") no-repeat 20px
      21px;
  }
}
.platform-area {
  label.control-label {
    &:after {
      content: " ";
      border: 2px solid #e1e1e1;
      border-radius: 20px;
      height: 18px;
      width: 18px;
    }
  }
  .form-type-radio {
    &.active label:after {
      content: " ";
      border: 2px solid #2cace3;
      background: transparent url("@{image-path}/server-check.png") no-repeat
        center center;
    }
  }
}
// Hide the weird validation indicators.
.form-type-password-confirm {
  .form-control-feedback {
    left: 100%;
    top: 0;
  }
  &.error-processed:before {
    display: none !important;
  }
  .has-error .form-control-feedback {
    display: none;
    &:before {
      display: none;
    }
  }
  .has-success .form-control-feedback {
    display: none;
    &:before {
      display: none;
    }
  }
  .has-warning .form-control-feedback {
    right: -33px;
  }
  .help-block {
    display: none !important;
  }
}
.form-item.has-error,
.form-item.error {
  &:before {
    display: none;
  }
}
label {
  display: block;
  color: #4b6180;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  .form-required {
    font: 0/0 none;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background: #4786ff;
    display: inline-block;
    position: absolute;
    margin-top: 8px;
    margin-left: 6px;
  }
}
input.form-control,
.chosen-container-single .chosen-single,
.chosen-container-multi .chosen-choices,
.chosen-container-active.chosen-with-drop .chosen-single {
  border-color: #f5f7fa !important;
  border-radius: 2px;
  background: #f5f7fa;
  padding: 0 16px;
  line-height: 40px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  &::placeholder,
  > span {
    color: #38485e;
    opacity: 0.5;
  }
  &:focus {
    border: 1px solid @button-hover !important;
    box-shadow: 0 0 2px @button-hover;
    padding: 0 15px;
    outline: none;
    background: #fff;
  }
}
.chosen-container-single:focus-within .chosen-single,
.chosen-container-multi:focus-within .chosen-choices {
  border: 1px solid @button-hover !important;
  box-shadow: 0 0 2px @button-hover;
  padding: 0 15px;
  outline: none;
  background-color: #fff !important;
  b {
    transform: rotate(180deg);
  }
}
#register-form {
  input.form-control,
  .chosen-container-single .chosen-single,
  .chosen-container-multi .chosen-choices,
  .chosen-container-active.chosen-with-drop .chosen-single {
    width: 284px;
  }
}
.page-user-password {
  .description {
    color: #4A495E;
    font-size: 15px;
    line-height: 24px;
    margin: 0 0 32px;
    @media (min-width: 768px) {
      width: 436px;
      margin: 0 auto 32px;
    }
  }
  #user-pass {
    width: 436px;
    label {
      display: block;
    }
  }
}
.form-group {
  margin-bottom: 16px;
}
.form-group.error,
.form-group.has-error {
  .control-label,
  label {
    color: #ec4646;
    .form-required {
      background: #ec4646;
    }
  }
  input,
  .chosen-single,
  .chosen-choices {
    background-color: #fff !important;
    border: 1px solid #ec4646 !important;
    &::placeholder,
    > span {
      color: #ec4646 !important;
      opacity: 1;
      font-weight: normal;
    }
    .chosen-search input {
      border: 1px solid #aaa;
    }
  }
}
.account-settings-tab {
  #block-system-main {
    form,
    .credit-card-empty {
      background: #fff;
      border-radius: 4px;
      padding: 32px;
    }
    .credit-card-empty .message-wrapper {
      background-color: #fcf8e3;
    }
    form.no-wrap {
      background: transparent;
      padding: 0;
    }
  }
}
#edit-picture {
  background: transparent;
  padding: 0;
  width: 185px;
  .panel-heading {
    display: none;
  }
  .user-picture img {
    max-width: 185px;
    max-height: 185px;
    border-radius: 2px;
  }
  .form-type-file {
    position: relative;
    label {
      font: inherit;
      background-color: #4786ff;
      color: #ffffff;
      border: none;
      border-radius: 4px;
      padding: 0px 32px 2px;
      height: 40px;
      line-height: 38px;
      cursor: pointer;
      font-size: 15px;
      font-weight: 500;
      display: inline-block;
      width: 100%;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 24px;
    }
    input[type="file"] {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
#edit-platform-plan {
  .panel-body {
    padding: 0 !important;
  }
  .production,
  .custom-field-wrapper {
    .form-item label {
      font-weight: 700;
      font-size: 14px;
      color: #38485e;
      width: 256px;
      @media (max-width: 579px) {
        width: 200px !important;
        line-height: 1.4 !important;
      }
    }
  }
  .feature-list {
    font-size: 14px;
    width: 100%;
    label {
      text-transform: none;
      font-weight: 600;
      font-size: 15px;
    }
    ul {
      display: flex;
      flex-direction: column;
      @media (min-width: 768px) {
        padding-left: 44%;
      }
      li,
      li.base-feature-1,
      li.base-feature-2,
      li.base-feature-3 {
        color: #38485e;
        background: transparent url("../images/icon-check.png") no-repeat 0 2px;
        background-size: auto 11px;
      }
    }
  }
  .custom-field-wrapper .form-item {
    label {
      padding-left: 0;
      float: left;
    }
    select {
      margin: 0;
      direction: rtl;
      padding-right: 24px;
      width: 124px !important;
      float: right;
    }
    select[disabled="disabled"] {
      background: transparent !important;
    }
    .help-block {
      font-size: 15px;
    }
  }
  .form-item-platform-base-platform-base-option {
    width: 124px;
  }
  fieldset.panel .cost,
  fieldset.panel .total,
  #number {
    background: #f5f7fa;
    line-height: 40px;
    height: 40px;
    font-size: 16px;
    font-weight: 700;
    padding: 0 12px;
    box-sizing: border-box;
    width: 97px;
    text-align: right;
  }
  #final-total {
    padding: 0;
    height: 40px;
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 248px;
    .ajax-progress {
      top: 0;
    }
  }
  .total-title {
    font-size: 13px;
    font-weight: 600;
    position: relative;
    line-height: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      color: inherit;
      vertical-align: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
  .info-dialog {
    display: inline-block;
    position: relative;

    > span {
      display: inline-flex;
      height: 40px;
      padding: 0 3px;
      align-items: center;
    }
    .tax-info {
      display: none;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 3px 10px rgba(152, 160, 171, 0.4);
      position: absolute;
      top: 100%;
      right: 0;
      font-weight: normal;
      padding: 16px 24px;
      color: #4b6180;
      line-height: 22px;
      width: 300px;
      z-index: 10;
      strong {
        color: #38485e;
      }
    }
    &:hover .tax-info {
      display: inline-block;
    }
  }
  .btn {
    padding: 0 16px;
    font-size: 15px;
  }
  .form-submit {
    float: right;
  }
  .cancel {
    text-transform: none;
    color: #4b6180;
    font-size: 15px;
    float: right;
    margin: 9px 30px 0 0;
  }
  .delete {
    float: left;
    margin-left: 0 !important;

    a.btn-danger {
      text-transform: none;
      border: 1px solid #c9d0e4;
      display: inline-block;
      padding: 0 16px;
      border-radius: 2px;
      line-height: 30px;
      height: 32px;
      font-size: 15px;
      &:hover {
        font-weight: inherit;
      }
    }
  }

  form {
    background: #fff;
    box-shadow: 0px 6px 44px rgba(152, 160, 171, 0.6);
    border-radius: 4px !important;
    min-width: 494px;
    .panel-body:before,
    .custom-field-wrapper .form-item:before {
      content: none !important;
    }
    #edit-platform-base,
    .custom-field-wrapper {
      padding: 20px 138px 20px 32px !important;
      margin: 0 !important;
    }
    #edit-platform-estimate {
      padding: 20px 32px !important;
      margin: 0 !important;
    }
    .price,
    .total {
      position: absolute;
      top: 20px !important;
      right: 32px !important;
    }
    .custom-field-wrapper {
      border-top: 1px solid #c9d0e4;

      .form-item {
        margin-bottom: 0 !important;
      }

      .help-block {
        width: 100%;
        margin: 0 !important;
        padding: 0 !important;
        @media (min-width: 1280px) {
          width: 570px;
          margin: 0 0 10px !important;
          padding-left: 20px !important;
        }
      }
      .required {
        display: none;
      }
    }

    .platform-currency-switcher {
      display: none;
      margin: 0;
    }
    .form-select {
      border: none;
      background: transparent url("../images/icon-chevron.svg") no-repeat right
        center !important;
      font-size: 14px !important;
    }
  }
}
